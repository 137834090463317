import type { FooterSegment } from 'src/config/footer';
import { getProductConfig } from 'src/config/product';

const whoWeAreUrl = getProductConfig('whoWeAreUrl');
const contactUsUrl = getProductConfig('contactUsUrl');
const newsUrl = getProductConfig('newsUrl');
const publishAdUrl = getProductConfig('publishAdUrl');
const immoSoftwareUrl = getProductConfig('immoSoftwareUrl');
const internationalLuxuryUrl = getProductConfig('internationalLuxuryUrl');
const cityGuideUrl = getProductConfig('cityGuideUrl');

const segments: FooterSegment[][] = [
  [
    {
      title: getProductConfig('brandName'),
      name: 'info',
      icon: 'info-circle',
      links: [
        ...(whoWeAreUrl
          ? [
              {
                label: 'ftr_anchor_who_we_are', // i18n-extract-keys ["ftr_anchor_who_we_are"]
                url: whoWeAreUrl,
                alternate: true,
              },
            ]
          : []),
        ...(contactUsUrl
          ? [
              {
                label: 'ftr_anchor_customer_care_contacts', // i18n-extract-keys ["ftr_anchor_customer_care_contacts"]
                url: contactUsUrl,
                alternate: true,
              },
            ]
          : []),
      ],
    },
    {
      title: 'ftr_title_real_estate_news', // i18n-extract-keys ["ftr_title_real_estate_news"]
      name: 'news',
      icon: 'newspaper',
      links: [
        ...(newsUrl
          ? [
              {
                label: 'ftr_anchor_blog_indomio', // i18n-extract-keys ["ftr_anchor_blog_indomio"]
                url: newsUrl,
                alternate: false,
              },
            ]
          : []),
      ],
    },
  ],
  [
    {
      title: 'ftr_title_agencies', // i18n-extract-keys ["ftr_title_agencies"]
      name: 'agencies',
      icon: 'suitcase',
      links: [
        ...(publishAdUrl
          ? [
              {
                label: 'ftr_anchor_publish_ad', // i18n-extract-keys ["ftr_anchor_publish_ad"]
                url: publishAdUrl,
                alternate: true,
              },
            ]
          : []),
        ...(immoSoftwareUrl
          ? [
              {
                label: 'ftr_anchor_re_sw_gest', // i18n-extract-keys ["ftr_anchor_re_sw_gest"]
                url: immoSoftwareUrl,
                alternate: true,
              },
            ]
          : []),
        ...(internationalLuxuryUrl
          ? [
              {
                label: 'ftr_anchor_international_visibility', // i18n-extract-keys ["ftr_anchor_international_visibility"]
                url: internationalLuxuryUrl,
                alternate: true,
              },
            ]
          : []),
      ],
    },
  ],
  [
    {
      title: 'ftr_title_privates', // i18n-extract-keys ["ftr_title_privates"]
      name: 'privates',
      icon: 'user-round',
      links: [
        ...(contactUsUrl
          ? [
              {
                label: 'ftr_anchor_faq', // i18n-extract-keys ["ftr_anchor_faq"]
                url: contactUsUrl,
                alternate: true,
              },
            ]
          : []),
        ...(cityGuideUrl
          ? [
              {
                label: 'ftr_anchor_house_prices', // i18n-extract-keys ["ftr_anchor_house_prices"]
                url: cityGuideUrl,
                alternate: true,
                isClientSideNavigation: true,
              },
            ]
          : []),
      ],
    },
  ],
];

export default { segments };
